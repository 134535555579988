<template>
	<div 
		v-if="messageEdit"
		class="flex flex-col content-start h-full overflow-hidden">
		
		<div 
			v-if="showHeading"
			class="flex-none bg-beige flex flex-col h-32">
			
			<div class="flex-grow flex px-4 pt-4">
				<div 
					class="flex-none w-12 h-12 flex items-center justify-center"
					@click="$emit('close')">
					
					<i class="fas fa-times text-black text-xl" />
				</div>
				<div class="flex-grow flex items-center justify-center truncate">
					<h1>Endre melding</h1>
					<!-- {{ message.messageId }} -->
				</div>
				<div class="flex-none w-12 h-12 flex items-center justify-center">
					
				</div>
			</div>
		</div>
		
		<section class="flex-grow py-2 px-8 overflow-auto">
			<!-- <pre>{{ messageEdit }}</pre> -->
			
			<form @submit.prevent>
				<InputMultiline 
					v-model="messageEdit.text"
					:required="true"
					label="Meldingstekst"
					name="text"
					classes="h-100"
				/>
				
				<legend>Hvem skal meldingen sendes til?</legend>
				
				<label for="everyone">
					<input 
						v-model="messageEdit.type"
						@change="messageEdit.departmentIds = []; messageEdit.usernames = [];"
						type="radio" 
						class="mr-2"
						id="everyone"
						value="everyone"
					/>
					
					Send til alle
				</label>
				
				<label for="departmentIds">
					<input 
						v-model="messageEdit.type"
						@change="messageEdit.departmentIds = []; messageEdit.usernames = [];"
						type="radio" 
						class="mr-2"
						id="departmentIds"
						value="departmentIds"
					/>
					
					Velg avdelinger
				</label>
					<div 
						v-if="messageEdit.type == 'departmentIds'"
						class="ml-8">
						
						<div v-if="departments && departments.length">
							<div 
								v-for="department in departments"
								:key="'department_'+department.departmentId"
								@click="onToggleDepartment(messageEdit, department.departmentId)"
								class="cursor-default flex my-4 items-start">
								
								<input 
									type="checkbox" 
									:checked="messageEdit.departmentIds.find( d => d == department.departmentId)"
								/>	
								{{department.name}}
							</div>
						</div>
						<em v-else>
							Ingen avdelinger funnet
						</em>
					</div>
					
				<label for="usernames">
					<input 
						v-model="messageEdit.type"
						@change="messageEdit.departmentIds = []; messageEdit.usernames = [];"
						type="radio" 
						class="mr-2"
						id="usernames"
						value="usernames"
					/>
					
					Velg brukere
				</label>
					<div 
						v-if="messageEdit.type == 'usernames'"
						class="ml-8">
						
						<div v-if="users && users.length">
							<div 
								v-for="user in users"
								:key="'user_'+user.username"
								@click="onToggleUsername(messageEdit, user.username)"
								class="cursor-default flex my-4 items-start">
								
								<input 
									type="checkbox" 
									:checked="messageEdit.usernames.find( u => u == user.username)"
								/>	
								
								{{ user.given_name && user.family_name ? `${user.given_name} ${user.family_name}` : user.email }}
							</div>
						</div>
						<em v-else>
							Ingen brukere funnet
						</em>
					</div>
			</form>
		</section>
		
		
		<div 
			v-if="messageEdit.messageId"
			class="flex-none p-6 flex">
			
			<div class="flex-grow space-x-4 ">
				<a 
					class="button warning" 
					@click.prevent="onDeleteMessage()" >
					
					Slett
				</a>
				<a 
					@click.prevent="onDuplicateMessage()" 
					:disabled="loading == 'duplicating'"
					class="button transparent"
					:class="{'loading' : loading == 'duplicating'}">
					
					Duplisér
				</a>
			</div>
			<div class="flex-none space-x-4 text-right">
				<a 
					class="button" 
					@click="$emit('close')">
					
					Avbryt
				</a>
				
				<button 
					type="submit"
					@click.prevent="onUpdateMessage()" 
					:disabled="loading == 'updating'"
					class="button submit"
					:class="{'loading' : loading == 'updating'}">
					
					Lagre
				</button>
			</div>
		</div>
		<div 
			v-else
			class="flex-none p-6">
			
			<div class="space-x-4 text-right">
				
				<a 
					class="button" 
					@click="$emit('close')">
					
					Avbryt
				</a>
				
				<button 
					type="submit"
					@click.prevent="onCreateMessage()" 
					:disabled="loading == 'creating'"
					class="button submit"
					:class="{'loading' : loading == 'creating'}">
					
					Lagre
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		message: {
			type: Object,
			required: true,
		},
		showHeading: {
			type: Boolean,
			required: false,
		},
	},
	
	computed:{
		departments(){
			return this.$store.getters.getDepartments;
		},
		
		users(){
			return this.$store.getters.getUsers;
		},
	},
	
	data() {
		return {
			loading: null,
			messageEdit: null,
		}
	},
	
	methods: {
		onToggleUsername( message, username ){
			if ( message.usernames.find( u => u == username) ) {
				message.usernames = message.usernames.filter( u => u != username );
			}
			else {
				message.usernames.push( username );
			}
		},
		
		onToggleDepartment( message, departmentId ){
			if ( message.departmentIds.find( d => d == departmentId) ) {
				message.departmentIds = message.departmentIds.filter( d => d != departmentId );
			}
			else {
				message.departmentIds.push( departmentId );
			}
		},
		
		async onCreateMessage(){
			try {
				if ( !this.messageEdit.text ) {
					throw Error('En melding må ha innhold');
				}
				
				this.loading = 'creating';
				this.messageEdit.author = {
					username: this.signedInUser.username,
					given_name: this.signedInUser.attributes.given_name,
					family_name: this.signedInUser.attributes.family_name,
					email: this.signedInUser.attributes.email,
					picture: this.signedInUser.attributes.picture || '',
				};
				
				console.log('this.messageEdit', this.messageEdit);
				
				const message = await this.$store.dispatch('createMessage', this.messageEdit);
				console.log('message response', message);
				
				this.$notify({ type: 'success', text: 'Meldingen ble opprettet' });
				this.$emit('close');
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke opprette meldingen'
				});
				
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
		
		async onDuplicateMessage(){
			try {
				if ( !this.messageEdit.text ) {
					throw Error('En melding må ha innhold');
				}
				
				this.loading = 'duplicating';
				delete this.messageEdit.messageId;
				
				this.messageEdit.author = {
					username: this.signedInUser.username,
					given_name: this.signedInUser.attributes.given_name,
					family_name: this.signedInUser.attributes.family_name,
					email: this.signedInUser.attributes.email,
					picture: this.signedInUser.attributes.picture || '',
				};
				
				console.log('this.messageEdit', this.messageEdit);
				
				const message = await this.$store.dispatch('createMessage', this.messageEdit);
				console.log('message response', message);
				
				this.$notify({ type: 'success', text: 'Meldingen ble opprettet' });
				this.$emit('close');
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke opprette meldingen'
				});
				
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
		
		async onUpdateMessage(){
			try {
				if ( !this.messageEdit.text ) {
					throw Error('En melding må ha innhold');
				}
				
				this.loading = 'updating';
				this.messageEdit.author = {
					username: this.signedInUser.username,
					given_name: this.signedInUser.attributes.given_name,
					family_name: this.signedInUser.attributes.family_name,
					email: this.signedInUser.attributes.email,
					picture: this.signedInUser.attributes.picture || '',
				};
				
				const message = await this.$store.dispatch('updateMessage', this.messageEdit);
				this.$notify({ type: 'success', text: 'Endringen ble lagret' });
				// this.$emit('close');
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke lagre endringen'
				});
		
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
		
		async onDeleteMessage(){
			try {
				if (! confirm('Er du sikker på at du vil slette denne meldingen?')) return; 
				
				this.loading = 'deleting';
				const message = await this.$store.dispatch('deleteMessage', this.messageEdit);
				this.$notify({ type: 'success', text: 'Meldingen ble slettet' });
				this.$emit('close');
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke lagre endringen'
				});
		
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
		
		createCopy(){
			this.messageEdit = JSON.parse(
				JSON.stringify({
					...this.message,
				})
			);
		},
	},
	
	async mounted(){
		this.createCopy();
	},
}
</script>